<template>
  <div class="service_details_container">
    <img class="title_image" src="../../assets/img_transporting.png" />
    <h4>START RIDING - QUICK BOOKING</h4>

    <ValidationObserver ref="observer" slim>
      <section>
        <h2>SERVICE OPTIONS</h2>
        <div class="form_section1">
          <table>
            <tr>
              <td class="width_td_33">
                <ValidationProvider
                  name="requester name"
                  rules="required|max:250"
                  v-slot="{ errors }"
                  slim
                >
                  <div class="label_and_element_wrapper travel_adj">
                    <a
                      class="yellow_bt"
                      @click="viewAddressBook('pickup')"
                      v-if="auth().user_type != 'user'"
                    >
                      ADDRESS BOOK
                      <i class="material-icons">account_box</i>
                    </a>
                    <label>
                      Requester Name
                      <span>*</span>
                    </label>

                    <input
                      type="text"
                      placeholder="Name Character Limit 250"
                      v-model="draft.requester_name"
                      :maxlength="max5"
                      :class="errors[0] ? 'warning-border':''"
                      v-if="auth().user_type == 'user'"
                      :disabled="auth().user_type == 'user' || updateType == true"
                    />

                    <input
                      v-else
                      type="text"
                      placeholder="Enter Character Limit 250"
                      v-model="draft.requester_name"
                      :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'"
                      @focus="show_pickup_suggestion = true"
                      @blur="hideSuggestions"
                      autocomplete="new-password"
                      :maxlength="max5"
                    />
                    <div class="dynamic_data_wrapper" v-if="show_pickup_suggestion">
                      <span
                        v-for="(address,i) in filteredPickupAddress"
                        :key="'pickup_address_'+i"
                        @click="populatePickupAddress(address)"
                      >{{address.name}}</span>
                    </div>
                  </div>
                </ValidationProvider>
              </td>
              <td class="width_td_33">
                <ValidationProvider name="unit name" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Branch
                      <span>*</span>
                    </label>

                    <select
                      v-model="draft.branch_id"
                      :class="errors[0] ? 'warning-border':''"
                      :disabled="auth().user_type == 'user' || updateType == true "
                    >
                      <option value>Select a branch</option>
                      <option
                        v-for="(branch,i) in branches"
                        :key="'branches'+i"
                        :value="branch.id"
                      >{{branch.name}}</option>
                    </select>
                  </div>
                </ValidationProvider>
              </td>
              <td class="width_td_33">
                <!-- <ValidationProvider name="request date" rules="required" v-slot="{ errors }" slim> -->
                <div class="label_and_element_wrapper">
                  <label>
                    Request Date
                    <span>*</span>
                  </label>
                  <flat-pickr
                    class="date_for_pos_fixed"
                    v-model="draft.request_date"
                    :config="configDate"
                    placeholder="Date"
                    name="birthdate"
                    :disabled="auth().user_type == 'user' || updateType == true "
                  ></flat-pickr>
                </div>
                <!-- </ValidationProvider> -->
              </td>
            </tr>
            <tr>
              <td>
                <ValidationProvider
                  name="Type of Booking"
                  rules="required"
                  v-slot="{ errors }"
                  slim
                >
                  <div class="label_and_element_wrapper">
                    <label>
                      Type of Booking
                      <span>*</span>
                    </label>
                    <select
                      v-model="draft.mode_of_dispatch"
                      :class="errors[0] ? 'warning-border':''"
                      :disabled="auth().user_type == 'user' || updateType == true "
                    >
                      <option value>Select a Booking</option>
                      <option value="Corporate">Corporate</option>
                    </select>
                  </div>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider
                  name="Mode of Booking"
                  rules="required"
                  v-slot="{ errors }"
                  slim
                >
                  <div class="label_and_element_wrapper">
                    <label>
                      Mode of Booking
                      <span>*</span>
                    </label>
                    <select
                      v-model="draft.booking_type"
                      :class="errors[0] ? 'warning-border':''"
                      :disabled="auth().user_type == 'user' || updateType == true "
                    >
                      <option value>Select a Mode</option>
                      <option value="Transport">Transport</option>
                    </select>
                  </div>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider
                  name="Number of Passengers"
                  rules="required"
                  v-slot="{ errors }"
                  slim
                >
                  <div class="label_and_element_wrapper">
                    <label>
                      Number of Passengers
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="0"
                      v-model="draft.no_of_passengers"
                      :class="errors[0] ? 'warning-border':''"
                      :disabled="updateType == true"
                    />
                  </div>
                </ValidationProvider>
              </td>
            </tr>
          </table>
        </div>
        <h2>APPROVER DETAILS</h2>
        <div class="form_section1">
          <table>
            <tr>
              <td>
                <div class="label_and_element_wrapper">
                  <label>Approver Name</label>
                  <input
                    type="text"
                    placeholder="Name"
                    v-model="draft.approver_name"
                    :disabled="updateType == true"
                  />
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>Approver Designation</label>
                  <input
                    type="text"
                    placeholder="Designation"
                    v-model="draft.approver_designation"
                    :disabled="updateType == true"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </section>

      <section>
        <h2>FLEET SERVICES</h2>
        <div class="form_section1">
          <div class="special_panel">
            <header>Service Name</header>
            <div class="sp_body">
              <div class="a_part">
                <ValidationProvider
                  name="delivery service"
                  rules="required"
                  v-slot="{ errors }"
                  slim
                >
                  <div class="label_and_element_wrapper sp">
                    <label>
                      Choose a delivery service
                      <span>*</span>
                    </label>
                    <select  v-model="draft.delivery_service" :class="errors[0] ? 'warning-border':''":disabled="updateType == true"  >
                      <option value>Select service</option>
                      <option
                        v-for="(service,i) in services" :value="service.name":key="'services'+i" >{{service.name}}</option>
                    </select>
                  </div>
                </ValidationProvider>
                <div class="service_logo">
                  <!-- <img :src="providers.logo" :alt="providers.name" /> -->
                  <img class="logo_img_travel" src="../../assets/ride-limo.png" v-if="draft.delivery_service == 'Limousine' " />
                  <img class="logo_img_travel" src="../../assets/ride-vip.png" v-if="draft.delivery_service == 'VIP' " />
                  <img class="logo_img_travel" src="../../assets/ride-bus.png" v-if="draft.delivery_service == 'Shuttle' " />
                </div>
              </div>
              <div class="a_part">
                <div class="infos">{{providers.description}}</div>
                <div v-if="providers.rate_card != 'null' ">
                  <a
                    class="view_rate_card"
                    v-if="providers.rate_card  "
                    :href="providers.rate_card"
                    target="_blank"
                    :disabled="updateType == true"
                  >
                    View Rate Card
                    <i class="material-icons">receipt</i>
                  </a>
                </div>
                <div v-else>
                  <h4>No rate card found</h4>
                </div>
              </div>
            </div>
          </div>

          <label class="lone_chk_label">
            <input
              name="usertype"
              type="radio"
              id="standard"
              v-model="has_standard"
              value="3"
              :disabled="updateType == true"
            />
            <span>Standard</span>
          </label>
          <label class="lone_chk_label">
            <input
              name="usertype"
              type="radio"
              v-model="draft.has_priority"
              value="1"
              :disabled="updateType == true"
            />
            <span>Priority Importance</span>
          </label>
          <label class="lone_chk_label">
            <input
              name="usertype"
              type="radio"
              v-model="draft.has_emergency"
              value="2"
              :disabled="updateType == true"
            />
            <span>Emergency</span>
          </label>
        </div>
      </section>
      <h4>JOURNEY DETAILS</h4>
      <section>
        <div class="form_section1">
          <div class="radio_wrapper1">
            <label>
              <input
                type="radio"
                name="entry_method"
                value="OneWay"
                id="OneWay"
                v-model="draft.trip_type"
                :disabled="updateType == true || editType == true"
              />
              <span>One Way</span>
            </label>
            <label>
              <input
                type="radio"
                name="entry_method"
                value="RoundTrip"
                id="RoundTrip"
                v-model="draft.trip_type"
                :disabled="updateType == true || editType == true"
              />
              <span>Round Trip</span>
            </label>
          </div>

          <div class="form_wrapper1">
            <table class="tbl1">
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Enter Date
                      <span>*</span>
                    </label>
                    <flat-pickr
                      class="date_for_pos_fixed"
                      v-model="draft.pickup_date"
                      :config="configDate"
                      placeholder="Date"
                      name="birthdate"
                      :disabled="updateType == true"
                    ></flat-pickr>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Start Time
                      <span>*</span>
                    </label>
                    <!-- <div class="input_groups">
                      <input type="text" value="10" />
                      <input type="text" value="30" />
                      <select>
                        <option>AM</option>
                        <option>PM</option>
                      </select>
                    </div>-->
                    <flat-pickr
                      :config="configTime"
                      placeholder="Select Time"
                      name="birthtime"
                      v-model="draft.pickup_time"
                      :disabled="updateType == true"
                    ></flat-pickr>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper" v-show="draft.trip_type === 'RoundTrip' ">
                    <label>
                      Return Time
                      <span>*</span>
                    </label>
                    <!-- <div class="input_groups"> -->
                    <!-- <input type="text" value="10" :disabled="draft.trip_type == 'OneWay' "/>
                      <input type="text" value="30" :disabled="draft.trip_type == 'OneWay' "/>
                      <select :disabled="draft.trip_type == 'OneWay' ">
                        <option>AM</option>
                        <option>PM</option>
                    </select>-->
                    <flat-pickr
                      :config="configTime"
                      placeholder="Select Time"
                      name="birthtime"
                      v-model="draft.return_time"
                      :disabled=" updateType == true"
                    ></flat-pickr>
                    <!-- </div> -->
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div style="clear:both"></div>
        </div>
      </section>
      <section>
        <div class="form_section1 travel_adj">
          <a class="black_bt" @click="clear(i)">Reset Fields</a>
          <h2 class="small_h2">START JOURNEY</h2>
          <table>
            <tr>
              <td style="width:50%">
                <div class="pickup_location_cover">
                  <div class="label_and_element_wrapper">
                    <label>
                      Pickup Location
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Location"
                      v-model="draft.pickup_city"
                      :disabled="updateType == true"
                         autocomplete="new-password"
                    />
                  </div>

                  <div class="label_and_element_wrapper">
                    <label></label>
                    <input
                      type="text"
                      placeholder="Street #"
                      v-model="draft.pickup_address_1"
                      :disabled="updateType == true"
                        autocomplete="new-password"
                    />
                  </div>

                  <div class="label_and_element_wrapper">
                    <label></label>
                    <input
                      type="text"
                      placeholder="Street Address"
                      v-model="draft.pickup_address_2"
                      :disabled="updateType == true"
                     autocomplete="new-password"
                           ref='pickup_address_2'
                 @focus="fetchLocationApi('pickup_address_2')"
                    />
                  </div>
                </div>
              </td>
              <td style="width:50%">
                <div class="pickup_location_cover">
                  <div class="label_and_element_wrapper">
                    <label>
                      Drop Location
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Location"
                      v-model="draft.delivery_city"
                      :disabled="updateType == true"
                      autocomplete="new-password"
                    />
                  </div>
                  <div class="label_and_element_wrapper">
                    <label></label>
                    <input
                      type="text"
                      placeholder="Street #"
                      v-model="draft.delivery_address_1"
                      :disabled="updateType == true"
                        autocomplete="new-password"
                    />
                  </div>

                  <div class="label_and_element_wrapper">
                    <label></label>
                    <input
                      type="text"
                      placeholder="Street Address"
                      v-model="draft.delivery_address_2"
                      :disabled="updateType == true"
                         ref='delivery_address_2'
                       @focus="fetchLocationApi('delivery_address_2')"
                         autocomplete="new-password"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <div v-show="draft.trip_type === 'RoundTrip' ">
            <h2 class="small_h2">RETURN JOURNEY</h2>
            <div>
              <label class="lone_chk_label">
                <input type="checkbox" v-model="checked" />
                <span>Use the relevant address details from above</span>
              </label>
            </div>
            <table v-if="checked">
              <tr>
                <td style="width:50%">
                  <div class="pickup_location_cover">
                    <div class="label_and_element_wrapper">
                      <label>
                        Pickup Location
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Location"
                        v-model="draft.return_pickup_city"
                        :disabled="draft.trip_type == 'OneWay' || updateType == true "
                          autocomplete="new-password"
                      />
                    </div>
                    <div class="label_and_element_wrapper">
                      <label></label>
                      <input
                        type="text"
                        placeholder="Street #"
                        v-model="draft.return_pickup_address_1"
                        :disabled="draft.trip_type == 'OneWay'  || updateType == true"
                          autocomplete="new-password"
                      />
                    </div>

                    <div class="label_and_element_wrapper">
                      <label></label>
                      <input
                        type="text"
                        placeholder="Street Address"
                        v-model="draft.return_pickup_address_2"
                        :disabled="draft.trip_type == 'OneWay' || updateType == true "
                          autocomplete="new-password"
                          ref='return_pickup_address_2'
                        @focus="fetchLocationApi('return_pickup_address_2')"
                      />
                    </div>
                  </div>
                </td>
                <td style="width:50%">
                  <div class="pickup_location_cover">
                    <div class="label_and_element_wrapper">
                      <label>
                        Drop Location
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Location"
                        v-model="draft.return_delivery_city"
                        :disabled="draft.trip_type == 'OneWay' || updateType == true "
                          autocomplete="new-password"
                      />
                    </div>
                    <div class="label_and_element_wrapper">
                      <label></label>
                      <input
                        type="text"
                        placeholder="Street #"
                        v-model="draft.return_delivery_address_1"
                        :disabled="draft.trip_type == 'OneWay' || updateType == true "
                          autocomplete="new-password"
                      />
                    </div>

                    <div class="label_and_element_wrapper">
                      <label></label>
                      <input
                        type="text"
                        placeholder="Street Address"
                        v-model="draft.return_delivery_address_2"
                        :disabled="draft.trip_type == 'OneWay' || updateType == true "
                          autocomplete="new-password"
                          ref='return_delivery_address_2'
                         @focus="fetchLocationApi('return_delivery_address_2')"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
      <h4>PASSENGER DETAILS</h4>
      <section>
        <div class="address_details_wrapper">
          <!-- <header>
            <a class="yellow_bt" @click="showEditContact = true">
              Address Book
              <i class="material-icons">account_box</i>
            </a>
          </header>-->
          <div class="form_section1">
            <table>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    name=" delivery name"
                    rules="required|max:150"
                    v-slot="{ errors }"
                    slim
                  >
                    <div class="label_and_element_wrapper">
                      <label>
                        Full Name
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Full Name"
                        v-model="draft.pickup_name"
                        :class="errors[0] ? 'warning-border':''"
                        :disabled="auth().user_type == 'user' || updateType == true "
                      />
                    </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    name=" delivery company"
                    rules="required|max:150"
                    v-slot="{ errors }"
                    slim
                  >
                    <div class="label_and_element_wrapper">
                      <label>
                        Company Name
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Company Name"
                        v-model="draft.pickup_company"
                        :class="errors[0] ? 'warning-border':''"
                        :disabled="auth().user_type == 'user' || updateType == true "
                      />
                    </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                <!--   <ValidationProvider
                    name=" delivery Branch"
                    rules="required|max:150"
                    v-slot="{ errors }"
                    slim
                  > -->
                    <div class="label_and_element_wrapper">
                      <label>
                        Branch Name
                        <!-- <span>*</span> -->
                      </label>
                      <input
                        type="text"
                        placeholder="Branch Name"
                        v-model="draft.pickup_branch"
                        :disabled="auth().user_type == 'user' || updateType == true "
                      />
                    </div>
                  <!-- </ValidationProvider> -->
                </td>
              </tr>

              <tr>
                <td>
                  <ValidationProvider
                    name=" delivery phone"
                    rules="required|max:150"
                    v-slot="{ errors }"
                    slim
                  >
                    <div class="label_and_element_wrapper">
                      <label>
                        Phone
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Phone"
                        v-model="draft.pickup_phone"
                        :class="errors[0] ? 'warning-border':''"
                        :disabled="auth().user_type == 'user' || updateType == true "
                      />
                    </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name=" delivery mobile" rules="required|max:150" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Mobile
                        <span></span>
                      </label>
                      <input
                        type="text"
                        placeholder="Mobile"
                        v-model="draft.pickup_mobile"
                        :disabled="auth().user_type == 'user' || updateType == true "
                      />
                    </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    name=" delivery email"
                    rules="required|max:150"
                    v-slot="{ errors }"
                    slim
                  >
                    <div class="label_and_element_wrapper">
                      <label>
                        Email
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Email"
                        v-model="draft.pickup_email"
                        :class="errors[0] ? 'warning-border':''"
                        :disabled="auth().user_type == 'user' || updateType == true "
                      />
                    </div>
                  </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="address_details_wrapper">
          <!-- <header>
            <h4>PICKUP INSTRUCTIONS</h4>
          </header>-->
          <div class="form_section1">
            <table>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    name=" delivery company"
                    rules="required|max:150"
                    v-slot="{ errors }"
                    slim
                  >
                    <div class="label_and_element_wrapper">
                      <label>
                        Enter Pickup Instructions
                        <span>*</span>
                      </label>
                      <textarea
                        class="pickup_details_journey"
                        placeholder="Pickup instructions"
                        v-model="draft.pickup_instructions"
                        :maxlength="max3"
                        :class="errors[0] ? 'warning-border':''"
                        :disabled="updateType == true"
                      ></textarea>
                    </div>
                  </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>

      <section v-show="updateTypeboolean" id="update_container">
        <h2>UPDATE TRANSPORT BOOKING DETAILS</h2>
        <div class="form_section1">
          <table>
            <tr>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    No. of Passengers
                    <span>*</span>
                  </label>
                </div>
              </td>
              <td>
                <ValidationProvider
                  name="no.of Passengers"
                  rules="required"
                  v-slot="{ errors }"
                  slim
                >
                  <div class="label_and_element_wrapper">
                    <input
                      type="text"
                      placeholder="Number of Passengers"
                      v-model="updatedraft.no_of_passengers"
                      :class="errors[0] ? 'warning-border':''"
                    />
                  </div>
                </ValidationProvider>
              </td>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Vehicle Model
                    <span>*</span>
                  </label>
                </div>
              </td>
              <td>
                <ValidationProvider name="Vehicle Model" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <input
                      type="text"
                      placeholder="Vehicle Model"
                      v-model="updatedraft.vehicle_model"
                      :class="errors[0] ? 'warning-border':''"
                    />
                  </div>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Travel Date
                    <span>*</span>
                  </label>
                </div>
              </td>
              <td>
                <ValidationProvider name="date" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <flat-pickr
                      :class="errors[0] ? 'warning-border':''"
                      v-model="updatedraft.dispatched_date"
                      :config="configDate"
                      placeholder="Date"
                      name="birthdate2"
                    ></flat-pickr>
                  </div>
                </ValidationProvider>
              </td>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Service
                    <span>*</span>
                  </label>
                </div>
              </td>
              <td>
                <ValidationProvider name="service" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <select
                      v-model="updatedraft.service_id"
                      :class="errors[0] ? 'warning-border':''"
                    >
                      <option value>Select service</option>
                      <option
                        v-for="(service,i) in services"
                        :value="service.service_provider_id"
                        :key="'services'+i"
                      >{{service.name}}</option>
                    </select>
                  </div>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Transport Company
                    <span>*</span>
                  </label>
                </div>
              </td>
              <td>
                <ValidationProvider
                  name="transport company"
                  rules="required|max:30"
                  v-slot="{ errors }"
                  slim
                >
                  <div class="label_and_element_wrapper">
                    <input
                      type="text"
                      placeholder="company"
                      v-model="updatedraft.dispatch_company"
                      :class="errors[0] ? 'warning-border':''"
                    />
                  </div>
                </ValidationProvider>
              </td>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Receipt Number
                    <span>*</span>
                  </label>
                </div>
              </td>
              <td>
                <ValidationProvider
                  name="receipt no"
                  rules="required|max:30"
                  v-slot="{ errors }"
                  slim
                >
                  <div class="label_and_element_wrapper">
                    <input
                      type="text"
                      placeholder
                      v-model="updatedraft.airway_bill"
                      :class="errors[0] ? 'warning-border':''"
                    />
                  </div>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td class="series_td">
                <ValidationProvider name="cost" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Cost
                      <span>*</span>
                    </label>
                  </div>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider name="cost" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <input
                      type="text"
                      placeholder="cost"
                      v-model="updatedraft.cost"
                      :class="errors[0] ? 'warning-border':''"
                    />
                  </div>
                </ValidationProvider>
              </td>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Other Charges
                    <span></span>
                  </label>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder v-model="updatedraft.other_charges" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Driver Name
                    <span></span>
                  </label>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder v-model="updatedraft.driver_name" />
                </div>
              </td>
              <td class="series_td">
                <div class="label_and_element_wrapper">
                  <label>
                    Vehicle Registration Number
                    <span></span>
                  </label>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder v-model="updatedraft.vehicle_reg_number" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="series_td" rowspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Reg Remarks
                    <span>*</span>
                  </label>
                </div>
              </td>
              <td>
                <ValidationProvider name="cost" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <textarea
                      placeholder="Enter the remarks"
                      v-model="updatedraft.reg_remarks"
                      :class="errors[0] ? 'warning-border':''"
                    ></textarea>
                  </div>
                </ValidationProvider>
              </td>
              <td class="series_td"></td>
              <td></td>
            </tr>
          </table>
        </div>
      </section>
    </ValidationObserver>

    <section class="centered">
      <a class="link_bt bt_cancel" @click="$router.push('/pages/ViewPendingTransport')">Cancel</a>
      <a
        class="link_bt bt_save"
        @click="submit"
        :disabled="quick_submit.disabled"
      >{{quick_submit.value}}</a>
    </section>

    <div class="black-overlay" v-show="showEditContact">
      <div class="box-modal">
        <div class="body">
          <AddressBookModal v-show="showEditContact" @closeModal="showEditContact = false" />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-show="viewconsignmentpopup_booking_id">
      <div class="box-modal printPops">
        <div class="body">
          <ViewConsignmentJourney
            @closeModal="closeConsignment"
            :booking_id="viewconsignmentpopup_booking_id"
            v-if="viewconsignmentpopup_booking_id"
          />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-if="address_book.pickup || address_book.delivery">
      <div class="box-modal">
        <div class="body">
          <AddressBookModal
            :selectedAddresses="[{id:draft.temp_pickup_address_id}]"
            v-if="address_book.pickup"
            @closeModal="closeModal"
            type="pickup"
            @selectedAddress="populatePickupAddress"
          />
          <!--  <AddressBookModal 
            v-if="address_book.delivery" 
            @closeModal="closeModal" 
            type="delivery" 
            :callfrom="draft.entry_type"
            @selectedAddress="populateDeliveryAddress"
            :selectedAddresses="draft.delivery_address"
          />-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import AddressBookModal from "./AddressBookModal.vue";
import moment from "moment";
import ViewConsignmentJourney from "./ViewConsignmentJourney.vue";

export default {
  name: "QuickTransportBooking",
  components: {
    flatPickr,
    AddressBookModal,
    ViewConsignmentJourney
  },
  computed: {
    filteredPickupAddress() {
      return this.address_list.filter(element => {
        if (
          element.name
            .toLowerCase()
            .includes(this.draft.requester_name.toLowerCase())
        ) {
          return element;
        }
      });
    },
    filteredDeliveryAddress() {
      return this.address_list.filter(element => {
        console.log(element);
        if (
          element.name
            .toLowerCase()
            .includes(this.draft.delivery_name.toLowerCase())
        ) {
          console.log(element);
          return element;
        }
      });
    }
  },
  mounted() {
    if (this.$route.params.type == "update") {
      this.$scrollTo("#update_container", 500, {
        offset: -120
      });
    }
  },
  props: {
    checked: false
  },
  watch: {
    "draft.pickup_city": function() {
      if (this.checked) {
        // this.second = this.first;
        this.draft.return_delivery_city = this.draft.pickup_city;
      }
    },
    "draft.pickup_address_1": function() {
      if (this.checked) {
        // this.second = this.first;
        this.draft.return_delivery_address_1 = this.draft.pickup_address_1;
      }
    },
    "draft.pickup_address_2": function() {
      if (this.checked) {
        // this.second = this.first;
        this.draft.return_delivery_address_2 = this.draft.pickup_address_2;
      }
    },

    "draft.delivery_city": function() {
      if (this.checked) {
        // this.second = this.first;
        this.draft.return_pickup_city = this.draft.delivery_city;
      }
    },
    "draft.delivery_address_1": function() {
      if (this.checked) {
        // this.second = this.first;
        this.draft.return_pickup_address_1 = this.draft.delivery_address_1;
      }
    },
    "draft.delivery_address_2": function() {
      if (this.checked) {
        // this.second = this.first;
        this.draft.return_pickup_address_2 = this.draft.delivery_address_2;
      }
    },
    checked: function(val) {
      if (val) {
        // this.second = this.first;
        this.draft.return_delivery_city = this.draft.pickup_city;
        this.draft.return_delivery_address_1 = this.draft.pickup_address_1;
        this.draft.return_delivery_address_2 = this.draft.pickup_address_2;

        this.draft.return_pickup_city = this.draft.delivery_city;
        this.draft.return_pickup_address_1 = this.draft.delivery_address_1;
        this.draft.return_pickup_address_2 = this.draft.delivery_address_2;
      }
    }
  },
  data() {
    return {
      has_standard: 1,
      viewconsignmentpopup_booking_id: "",
      updateType: true,
      updateTypeboolean: "",
      delivery_addresses_count: 0,
      max: 30,
      max1: 60,
      max2: 10,
      max3: 70,
      max4: 100,
      max5: 250,
      max6: 150,
      configDate: {
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true,
        minDate: "today"
      },
      configTime: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      },
      showEditContact: false,
      draft: {
        request_date: moment(new Date()).format("YYYY-MM-DD"),
        trip_type: "OneWay",
        requester_name: "",
        branch_id: "",
        approver_name: "",
        approver_designation: "",
        service_id: "",
        delivery_service:"",
        pickup_date: moment(new Date()).format("YYYY-MM-DD"),
        pickup_time: this.moment(new Date()).format("h:mm:ss"),
        return_time: this.moment(new Date()).format("h:mm:ss"),
        has_priority: 0,
        has_emergency: 0,
        temp_pickup_address_id:"",
        pickup_name: "",
        pickup_company: "",
        pickup_branch: "",
        pickup_address_1: "",
        pickup_address_2: "",
        pickup_city: "",
        pickup_phone: "",
        pickup_mobile: "",
        pickup_email: "",
        delivery_city: "",
        delivery_address_1: "",
        delivery_address_2: "",
        pickup_instructions: "",
        booking_type: "Transport",
        mode_of_dispatch: "Corporate",
        no_of_passengers: "1",
        delivery_type: "",
        return_pickup_address_1: "",
        return_pickup_address_2: "",
        return_pickup_city: "",
        return_delivery_address_1: "",
        return_delivery_address_2: "",
        return_delivery_city: ""
      },
      address_book: {
        pickup: false,
        delivery: false
      },
      hideptaeo_suggestion: false,
      provider_details: "",
      show_pickup_suggestion: false,
      show_destination_suggestion: false,
      pickupAddressCount: 0,
      selectedAddresses: [],
      branches: [],
      dispatches: [],
      goods: [],
      providers: [],
      services: [],
      select_ptaeo_suggestions: [],
      ptaeo_details: [],
      address_list: [],
      save_btn: {
        disabled: false,
        value: "SAVE AS DRAFT"
      },
      quick_submit: {
        disabled: false,
        value: "SUBMIT"
      },
      updatedraft: {
        no_of_passengers: "",
        vehicle_model: "",
        dispatched_date: moment(new Date()).format("YYYY-MM-DD"),
        service_id: "",
        dispatch_company: "",
        airway_bill: "",
        cost: "",
        other_charges: "",
        driver_name: "",
        vehicle_reg_number: "",
        reg_remarks: ""
      },
      editTypeboolean: "",
      editType: false
    };
  },
  created() {
    this.$emit("breadCrumbData", ["Transport", "Quick Transport Booking"]);
    if (this.draft.has_emergency == 0 && this.draft.has_priority == 0) {
      this.has_standard = 3;
    }
    if (this.$route.params.type == "update") {
      this.updateTypeboolean = this.$route.params.type;
      this.updateType = true;
      this.save_btn.value = "SUBMIT";
    } else {
      this.updateType = false;
    }

    this.user = this.auth();
    if (this.$route.params.id == undefined) {
        this.axios
          .get("/api/user/data")
          .then(response => {
            console.log(response);
            this.draft.requester_name = response.data.msg.name;
            this.draft.branch_id = response.data.msg.branch.id;
            /*      this.draft.requester_name = response.data.msg.name;
            this.draft.requester_branch = response.data.msg.branch.name;
            this.draft.requester_company = response.data.msg.company.name;
            this.draft.requester_email = response.data.msg.email;
            this.draft.requester_phone = response.data.msg.phone;
            this.draft.requester_mobile = response.data.msg.mobile;*/
          })
          .catch(error => {
            console.log(error);
          });
        this.fetchUserData();
    } else {
      this.axios
        .get("/api/courier/domestic/" + this.$route.params.id)
        .then(response => {
          console.log(response);
          this.draft.trip_type = response.data.courier.transport.trip_type;
          console.log(this.$route.params.type);
          this.editTypeboolean = this.$route.params.type;
          if (this.editTypeboolean == "edit") {
            this.editType = true;
            if (this.draft.trip_type == "OneWay") {
              this.single = this.draft.trip_type;
            } else {
              this.draft.trip_type = "OneWay";
            }
          } else if (this.editTypeboolean == "update") {
            this.updateType = true;
            this.draft.trip_type = "OneWay";
          }
          this.draft.booking_type = response.data.courier.booking_type;
          this.draft.no_of_passengers =
            response.data.courier.transport.no_of_passengers;
          this.draft.request_date = response.data.courier.request_date;
          this.draft.requester_name = response.data.courier.requester_name;
          this.draft.branch_id = response.data.courier.branch_id;
          this.draft.approver_name = response.data.courier.approver_name;
          this.draft.approver_designation =
            response.data.courier.approver_designation;
          this.draft.mode_of_dispatch = response.data.courier.mode_of_dispatch;
          this.draft.pickup_date = moment(
            response.data.courier.pickup_date
          ).format("YYYY-MM-DD");
          this.draft.pickup_time = response.data.courier.pickup_time;
          this.draft.return_time = response.data.courier.return_time;
          this.draft.service_id = response.data.courier.service_id;
          this.draft.delivery_service = response.data.courier.delivery_service;
          this.draft.pickup_name = response.data.courier.pickup_name;
          this.draft.pickup_company = response.data.courier.pickup_company;
          this.draft.pickup_branch = response.data.courier.pickup_branch;
          this.draft.pickup_phone = response.data.courier.pickup_phone;
          this.draft.pickup_mobile = response.data.courier.pickup_mobile;
          this.draft.pickup_email = response.data.courier.pickup_email;
          this.draft.pickup_address_1 = response.data.courier.pickup_address_1;
          this.draft.pickup_address_2 = response.data.courier.pickup_address_2;
          this.draft.pickup_city = response.data.courier.pickup_city;
          this.draft.delivery_address_1 =
            response.data.courier.delivery_address_1;
          this.draft.delivery_address_2 =
            response.data.courier.delivery_address_2;
          this.draft.delivery_city = response.data.courier.delivery_city;
          this.draft.pickup_instructions =
            response.data.courier.pickup_instructions;

          this.draft.return_pickup_city =
            response.data.courier.return_pickup_city;
          this.draft.return_pickup_address_1 =
            response.data.courier.return_pickup_address_1;
          this.draft.return_pickup_address_2 =
            response.data.courier.return_pickup_address_2;
          this.draft.return_delivery_city =
            response.data.courier.return_delivery_city;
          this.draft.return_delivery_address_1 =
            response.data.courier.return_delivery_address_1;
          this.draft.return_delivery_address_2 =
            response.data.courier.return_delivery_address_2;

          this.draft.has_priority = response.data.courier.has_priority;
          this.draft.has_emergency = response.data.courier.has_emergency;
          if (this.draft.has_priority == 1) {
            this.draft.has_priority = 1;
            this.draft.has_emergency = 0;
          }
          if (this.draft.has_emergency == 1) {
            this.draft.has_emergency = 2;
            this.draft.has_priority = 0;
          }
          if (this.draft.has_emergency == 0 && this.draft.has_priority == 0) {
            this.has_standard = 3;
          }
          this.updatedraft.no_of_passengers =
            response.data.courier.transport.no_of_passengers;
          this.updatedraft.vehicle_model =
            response.data.courier.transport.vehicle_model;
          if (response.data.courier.dispatch_date) {
            this.updatedraft.dispatched_date =
              response.data.courier.dispatch_date;
          } else {
            this.updatedraft.dispatched_date = moment(new Date()).format(
              "YYYY-MM-DD"
            );
          }
          this.updatedraft.service_id = response.data.courier.service_id;
          this.updatedraft.dispatch_company =
            response.data.courier.dispatch_company;
          this.updatedraft.airway_bill = response.data.courier.airway_bill;
          this.updatedraft.cost = response.data.courier.cost;
          this.updatedraft.other_charges = response.data.courier.other_charges;
          this.updatedraft.driver_name =
            response.data.courier.transport.driver_name;
          this.updatedraft.vehicle_reg_number =
            response.data.courier.transport.vehicle_reg_number;
          this.updatedraft.reg_remarks = response.data.courier.reg_remarks;
        })
        .catch(error => {
          console.log(error);
        });
    }
    this.fetchBranch();
    this.fetchProviders();
    this.fetchAddresses();
  },
  methods: {
    clear(i) {
      this.draft.pickup_city = "";
      this.draft.pickup_address_1 = "";
      this.draft.pickup_address_2 = "";
      this.draft.delivery_city = "";
      this.draft.delivery_address_1 = "";
      this.draft.delivery_address_2 = "";
      this.draft.return_pickup_city = "";
      this.draft.return_pickup_address_1 = "";
      this.draft.return_pickup_address_2 = "";
      this.draft.return_delivery_city = "";
      this.draft.return_delivery_address_1 = "";
      this.draft.return_delivery_address_2 = "";
    },
     fetchLocationApi(type) {
      // return;
      let options = {
       
        types: ["geocode"]
      };
      let map = new google.maps.places.Autocomplete(this.$refs[type], options);
      map.addListener("place_changed", () => {
        let place = map.getPlace();
        this.draft[type] = place.formatted_address;
      });
    },
    hideSuggestions() {
      setTimeout(() => {
        this.show_pickup_suggestion = false;
        this.show_destination_suggestion = false;
      }, 200);
    },
    fetchBranch() {
      this.axios
        .get("/api/branch/")
        .then(response => {
          console.log(response);
          this.branches = response.data.branches;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchProviders() {
      let carrierCode = "Transport";
      this.axios
        .get("/api/provider/transport/" + carrierCode)
        .then(response => {
          console.log(response);
          this.providers = response.data.provider;

          //console.log(provider);
          this.services = response.data.provider[0].services;
          console.log("dfdf");
          console.log(response.data.provider[0].services)
          if (!this.editTypeboolean) {
            if (this.services.length > 0) {
              this.draft.delivery_service = this.services[0].name;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    viewAddressBook(type) {
      console.log(type);
      Object.keys(this.address_book).map(element => {
        this.address_book[element] = false;
      });
      this.address_book[type] = true;
    },
    fetchUserData() {
      this.loading = true;
      this.axios
        .get("/api/user/data")
        .then(response => {
          console.log(response);
          this.draft.pickup_name = response.data.msg.name;
          this.draft.pickup_branch = response.data.msg.branch
            ? response.data.msg.branch.name
            : "";
          this.draft.pickup_address_1 = response.data.msg.address_1;
          this.draft.pickup_address_2 = response.data.msg.address_2;
          this.draft.pickup_postal_code = response.data.msg.postal_code;
          this.draft.pickup_city = response.data.msg.city;
          this.draft.pickup_suburb = response.data.msg.suburb;
          this.draft.pickup_state = response.data.msg.state;
          this.draft.pickup_country = response.data.msg.country;
          this.draft.pickup_phone = response.data.msg.phone;
          this.draft.pickup_mobile = response.data.msg.mobile;
          this.draft.pickup_email = response.data.msg.email;
          this.draft.pickup_company = response.data.msg.company.name;

          this.draft.return_delivery_city = response.data.msg.city;
          this.draft.return_delivery_address_1 = response.data.msg.address_1;
          this.draft.return_delivery_address_2 = response.data.msg.address_2;

          this.show_pickup_suggestion = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    populatePickupAddress(value) {
      console.log(value);
      this.draft.temp_pickup_address_id = value.id;
      this.draft.requester_name = value.name;
      this.draft.branch_id = value.branch.id;
      this.draft.pickup_name = value.name;
      this.draft.pickup_branch = value.branch_name;
      this.draft.pickup_company = value.company_name;
      this.draft.pickup_address_1 = value.address_1;
      this.draft.pickup_address_2 = value.address_2;
      this.draft.pickup_postal_code = value.postal_code;
      this.draft.pickup_city = value.city;
      this.draft.pickup_suburb = value.suburb;
      this.draft.pickup_state = value.state;
      this.draft.pickup_country = value.country;
      this.draft.pickup_phone = value.phone;
      this.draft.pickup_mobile = value.mobile;
      this.draft.pickup_email = value.email;

      this.draft.return_delivery_city = value.city;
      this.draft.return_delivery_address_1 = value.address_1;
      this.draft.return_delivery_address_2 = value.address_2;
    },
    populateDeliveryAddress(addresses) {
      if (this.draft.entry_type == "single") {
        addresses = [addresses];
      }
      let selectedAddresses = addresses[0];
      console.log(selectedAddresses);
      this.draft.delivery_name = selectedAddresses.name;
      this.draft.delivery_company = selectedAddresses.company_name;
      this.draft.delivery_branch = selectedAddresses.branch_name;
      this.draft.delivery_department = selectedAddresses.department
        ? selectedAddresses.department.department_name
        : "";
      this.draft.delivery_address_1 = selectedAddresses.address_1;
      this.draft.delivery_address_2 = selectedAddresses.address_2;
      this.draft.delivery_postal_code = selectedAddresses.postal_code;
      this.draft.delivery_city = selectedAddresses.city;
      this.draft.delivery_suburb = selectedAddresses.suburb;
      this.draft.delivery_state = selectedAddresses.state;
      this.draft.delivery_country = selectedAddresses.country;
      this.draft.delivery_phone = selectedAddresses.phone;
      this.draft.delivery_mobile = selectedAddresses.mobile;
      this.draft.delivery_email = selectedAddresses.email;
      this.draft.address_book_id = selectedAddresses.id;

      this.mapDeliveryAddresses(addresses);
      this.delivery_addresses_count = this.draft.delivery_address.length;
      this.show_destination_suggestion = false;
    },
    mapDeliveryAddresses(addresses, selected_from_address_book = true) {
      let i = 0;
      this.draft.delivery_address = [];
      addresses.forEach(address => {
        this.draft.delivery_address[i] = {
          delivery_name: address.name,
          delivery_company: address.company_name,
          delivery_branch: address.branch_name,
          delivery_department: address.department
            ? address.department.department_name
            : "",
          delivery_address_1: address.address_1,
          delivery_address_2: address.address_2,
          delivery_postal_code: address.postal_code,
          delivery_city: address.city,
          delivery_suburb: address.suburb,
          delivery_state: address.state,
          delivery_country: address.country,
          delivery_phone: address.phone,
          delivery_mobile: address.mobile,
          delivery_email: address.email,
          address_book_id: selected_from_address_book
            ? address.id
            : address.address_book_id
            ? address.address_book_id
            : address.id
        };
        i++;
      });
    },
    addressSelected(selectedAddresses) {
      console.log(selectedAddresses); // You will get the address here
    },
    fetchAddresses() {
      this.axios
        .post("api/address/list")
        .then(response => {
          console.log(response);
          this.address_list = response.data.msg;
        })
        .catch(error => {
          console.log(error);
        });
    },
    closeModal(value) {
      this.address_book[value.type] = false;
    },
    submit() {
      this.$refs.observer.validate();
      this.quick_submit.disabled = true;
      this.quick_submit.value = "Saving.. Please Wait";

      if (this.draft.has_priority == 1) {
        this.draft.has_priority = 1;
        this.draft.has_emergency = 0;
        this.has_standard = 0;
        this.draft.delivery_type = "priority";
      } else if (this.draft.has_emergency == 2) {
        this.draft.has_emergency = 1;
        this.draft.has_priority = 0;
        this.has_standard = 0;
        this.draft.delivery_type = "emergency";
      } else {
        this.has_standard = 1;
        this.draft.has_priority = 0;
        this.draft.has_emergency = 0;
        this.draft.delivery_type = "standard";
      }

      if (this.$route.params.id) {
        if (this.editTypeboolean == "edit") {
          console.log(this.draft);
          this.axios
            .put("/api/transport/" + this.$route.params.id, this.draft)
            .then(response => {
              console.log(response);
              this.toast.success(response.data.msg);
              this.viewconsignmentpopup_booking_id = response.data.booking_id;
              this.quick_submit.disabled = false;
              this.quick_submit.value = "SUBMIT";
            })
            .catch(error => {
              console.log(error);
              this.quick_submit.disabled = false;
              this.quick_submit.value = "SUBMIT";
            });
        } else if (this.updateTypeboolean == "update") {
          console.log(this.updatedraft);
          this.axios
            .put(
              "/api/courier/transport/domestic/" + this.$route.params.id,
              this.updatedraft
            )
            .then(response => {
              console.log(response);
              this.toast.success(response.data.msg);
              this.$router.push("/pages/ViewPendingTransport");
              this.quick_submit.value = "SUBMIT";
            })
            .catch(error => {
              this.quick_submit.disabled = false;
              this.quick_submit.value = "SUBMIT";
              console.log(error);
            });
        }
      } else {
        if (this.draft.trip_type == "RoundTrip" && !this.checked) {
          this.draft.return_pickup_city = this.draft.delivery_city;
          this.draft.return_pickup_address_1 = this.draft.delivery_address_1;
          this.draft.return_pickup_address_2 = this.draft.delivery_address_2;
        }

        console.log(this.draft);
        this.axios
          .post("/api/transport", this.draft)
          .then(response => {
            console.log(response);
            this.viewconsignmentpopup_booking_id = response.data.booking_id;
            this.toast.success(response.data.msg);
            this.quick_submit.disabled = false;
            this.quick_submit.value = "SUBMIT";
          })
          .catch(error => {
            console.log(error);
            this.quick_submit.disabled = false;
            this.quick_submit.value = "SUBMIT";
          });
      }
    },
    closeConsignment() {
      this.viewconsignmentpopup_booking_id = "";
      this.$router.push("/pages/ViewPendingTransport");
      this.courier = this.courier.map(element => {
        element.show_view_popup = false;
        return element;
      });
    }
  }
};
</script>
<style scoped>
textarea.pickup_details_journey {
  height: 415px;
}
.form_wrapper1 {
  width: 100%;
  height: auto;
  display: flex;
}
.form_wrapper1 table.tbl1 {
  width: 100%;
}
.form_wrapper1 table.tbl1 td {
  width: 25%;
}
.form_wrapper1 table.tbl1 td:first-child {
  width: 50%;
}
.input_groups {
  display: flex;
}
.input_groups input {
  margin-right: 5px;
  width: 30%;
}
.input_groups select {
  margin-right: 5px;
  width: 40%;
}
.service_details_container {
  padding: 20px;
}
.service_details_container section {
  padding: 20px 0 0 0;
}
.service_details_container section h2 {
  font-size: 18px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: inline-block;
}
.title_image {
  display: block;
  margin: auto;
}
h4 {
  font-weight: 600;
  text-align: center;
  padding: 30px 0;
  font-size: 18px;
}
.step_view_wrapper {
  width: 100%;
  height: 140px;
  background: #181818;
  padding: 20px 80px;
  position: relative;
}
.step_view_wrapper .strip {
  width: calc(100% - 160px);
  height: 10px;
  background: #666;
  position: absolute;
  top: 50%;
  line-height: 0;
}
.step_view_wrapper .strip_half {
  height: 10px;
  width: 50%;
  background: #06A5ED;
  display: inline-block;
  display: none;
}
.step_view_wrapper .circle {
  width: 30px;
  height: 30px;
  background: #000;
  border: 5px solid #06A5ED;
  border-radius: 100px;
  position: absolute;
  left: -10px;
  top: -10px;
}
.step_view_wrapper .circle.inactive {
  background: #ffffff;
  border-color: #999;
}
.step_view_wrapper .circle.two {
  left: 50%;
  margin-left: -10px;
}
.step_view_wrapper .circle.three {
  left: 100%;
  margin-left: -10px;
}
.step_view_wrapper .circle_labels {
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  width: 120px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  top: 40px;
  left: -60px;
}

.radio_wrapper1 {
  width: 300px;
  height: 70px;
  display: flex;
  padding: 20px 0;
}
.radio_wrapper1 label {
  width: auto;
  height: 30px;

  display: block;
  margin: 5px;
}
.radio_wrapper1 label input[type="radio"] {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}
.radio_wrapper1 label span {
  width: auto;
  height: 20px;
  display: inline-block;
  margin-right: 30px;
  position: relative;
  top: -5px;
}

.radio_with_img_labels {
  width: 180px;
  height: 80px;
  background: #fff;
  color: #333;
  padding: 5px;
  margin: 10px 20px;
  display: inline-block;
}
.radio_with_img_labels img {
  display: block;
  margin: auto;
  height: 40px;
}
.radio_with_img_labels input[type="radio"] {
  width: 20px;
  height: 20px;
  display: block;
  margin: auto;
}

.special_panel {
  background: #fff;
  width: 100%;
}
.special_panel header {
  height: 40px;
  background: #004a7c;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  padding: 0 20px;
}
.special_panel .sp_body {
  background: #fff;
  padding: 20px;
}
.special_panel .sp_body .a_part {
  width: 50%;
  display: inline-block;
  position: relative;
  height: 100%;
}
.special_panel .sp_body .a_part .infos {
  padding: 20px 0;
  color: #333;
  font-size: 16px;
}
.special_panel .sp_body .a_part .view_rate_card {
  display: block;
  background: #06A5ED;
  color: #fff;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  right: 20px;
  bottom: -125px;
}
.label_and_element_wrapper.sp {
  width: 70%;
}
.lone_chk_label {
  padding: 20px;
  display: inline-block;
}
.lone_chk_label span {
  display: inline-block;
  position: relative;
  top: -5px;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.address_details_wrapper {
  width: 45%;
  height: auto;
  display: inline-block;
  margin: 0 2%;
  overflow: hidden;
}
.adjust_width_full_wrapper {
  width: 94%;
  height: auto;
  display: inline-block;
  margin: 0 2%;
}
.address_details_wrapper header {
  height: 60px;
}
.address_details_wrapper header h4 {
  font-size: 18px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: block;
  float: left;
}
.address_details_wrapper header h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: block;
  float: left;
}
.address_details_wrapper header .yellow_bt {
  font-size: 20px;
  padding: 0 10px;
  float: right;
  width: 200px;
  height: 40px;
  background: #063657;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  display: block;
}
.address_details_wrapper header .yellow_bt i {
  font-size: 24px;
}

.service_logo {
  width: 300px;
  height: auto;
  padding: 20px 0;
}
.service_logo img {
  display: block;
  width: 100%;
  height: auto;
}
.selected_count {
  width: 130px;
  height: 40px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 40px;
  color: rgb(8, 82, 243);
  float: right;
  box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  float: right;
  margin-right: 20px;
}
.selected_count .material-icons {
  font-size: 40px;
  top: -1px;
  left: -10px;
  position: relative;
  margin-right: 0;
}
.selected_count span {
  display: inline-block;
  position: relative;
  top: -13px;
  left: -8px;
}

.travel_adj {
  position: relative;
}
.travel_adj .yellow_bt {
  font-size: 14px;
  padding: 0 10px;
  float: right;
  width: 140px;
  height: 30px;
  background: #063657;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-weight: 600;
  display: block;
  position: relative;
  top: -7px;
}

.travel_adj .black_bt {
  font-size: 14px;
  padding: 0 10px;
  float: right;
  width: 140px;
  height: 30px;
  background: #004a7c;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-weight: 600;
  display: block;
  position: relative;
  top: -7px;
  margin-bottom: -40px;
}
.travel_adj .yellow_bt i.material-icons {
  font-size: 18px;
  margin: 0 3px;
}

.pickup_location_cover {
  display: flex;
}
.pickup_location_cover .label_and_element_wrapper {
  width: 25%;
}
.pickup_location_cover .label_and_element_wrapper:last-child {
  width: 50%;
}
</style>